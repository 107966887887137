import React from "react"
import Layout from "../components/layout"
import SEO from "../components/contact/seo"
import ContactHeader from "../components/contact/contactHeader"
import ContactContent from "../components/support/index"
import ContactBottomInfo from "../components/contact/contactBottomInfo"

const SupportPage = () => {
  return (
    <div>
      <Layout>
        <SEO />
        <ContactHeader />
        <ContactContent />
        <ContactBottomInfo />
      </Layout>
    </div>
  )
}

export default SupportPage

import React, { useState } from "react"
import ContactSupport from "../contact/contactSupport"
import { Link } from "gatsby"
import "./style.sass"

const ContactContent = () => {
  const [showForm, setShowForm] = useState(true)

  return (
    <div id="contact_content">
      <div className="container">
        <div className="buttons">
          <Link to="/contact/" className="showForm">
            Sales
          </Link>
          <Link to="/support/" className="showForm active">
            Support
          </Link>
          {/* <button onClick={() => setShowForm(true)} className={showForm && "active"}>Sales</button>
          <button onClick={() => setShowForm(false)} className={!showForm && "active"}>Support</button> */}
        </div>
      </div>

      <ContactSupport />
    </div>
  )
}

export default ContactContent
